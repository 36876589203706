<template>
  <section>
    <v-form>
      <v-select
        placeholder="Address Type"
        outlined
        :items="addressesTypes"
        v-model="addressType"
        item-text="name"
        item-value="value"
        label="Address Type"
      ></v-select>
      <v-text-field
        v-model="fullname"
        outlined
        placeholder="Full Name"
        label="Full Name"
      ></v-text-field>
      <v-text-field
        v-model="email"
        outlined
        placeholder="Email Address"
        label="Email Address"
      ></v-text-field>
      <v-text-field
        v-model="phone1"
        outlined
        placeholder="Phone Number 1"
        label="Phone Number 1"
      ></v-text-field>

      <v-text-field
        v-model="phone2"
        outlined
        placeholder="Phone Number 2"
        label="Phone Number 2"
      ></v-text-field>
      <v-select
        placeholder="State/Province/Region"
        outlined
        :items="provinces"
        item-text="name"
        item-value="value"
        v-model="province"
        label="Province"
      ></v-select>
      <v-select
        placeholder="Inside Valley"
        outlined
        :items="insideValleys"
        item-text="name"
        item-value="value"
        v-model="valley"
        v-if="province == 3"
        label="Valley"
      ></v-select>
      <v-select
        placeholder="Inside Ringroad"
        outlined
        :items="insideRingroads"
        item-text="name"
        item-value="value"
        v-model="ringRoad"
        v-if="valley == 'insideValley'"
        label="Ringroad"
      ></v-select>
      <v-text-field
        v-model="address"
        outlined
        placeholder="Address"
        label="Address"
      ></v-text-field>
      <v-text-field
        v-model="nearestLandmark"
        outlined
        placeholder="Nearest Landmark"
        label="Nearest Landmark"
      ></v-text-field>
      <v-btn v-if="!loading" @click="addAddress">Save</v-btn>
      <v-btn v-if="loading" loading></v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="color"> {{ message }}</v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddressForm",
  data: () => ({
    fullname: undefined,
    snackbar: false,
    color: "black",
    message: undefined,
    addressesTypes: [
      {
        name: "Shipping Address",
        value: "shippingAddress",
      },
      {
        name: "Billing Address",
        value: "billingAddress",
      },
    ],
    provinces: [
      {
        name: "Province 1",
        value: "1",
      },
      {
        name: "Madesh Pradesh",
        value: "2",
      },
      {
        name: "Bagmati Pradesh",
        value: "3",
      },
      {
        name: "Gandaki Pradesh",
        value: "4",
      },
      {
        name: "Lumbini Pradesh",
        value: "5",
      },
      {
        name: "Karnali Pradesh",
        value: "6",
      },
      {
        name: "Surdhurpaschim Pradesh",
        value: "7",
      },
    ],
    insideValleys: [
      {
        name: "Inside Valley",
        value: "insideValley",
      },
      {
        name: "Outside Valley",
        value: "outsideValley",
      },
    ],
    insideRingroads: [
      {
        name: "Inside Ringroad",
        value: "insideRingroad",
      },
      {
        name: "Outside Ringroad",
        value: "outsideRingroad",
      },
    ],
    addressType: undefined,
    province: undefined,
    valley: undefined,
    ringRoad: undefined,
    loading: false,
    nearestLandmark: undefined,
    address: undefined,
    phone1: undefined,
    phone2: undefined,
    email: undefined,
  }),
  methods: {
    ...mapActions(["postAddress", "getSingleAddress"]),
    async addAddress() {
      this.loading = true;
      let data = {};
      data.addressType = this.addressType;
      data.fullname = this.fullname;
      data.email = this.email;
      data.phone1 = this.phone1;
      data.phone2 = this.phone2;
      data.province = this.province;
      data.address = this.address;
      data.nearestLandmark = this.nearestLandmark;
      if (this.valley != undefined) {
        data.valley = this.valley;
      }
      if (this.ringRoad != undefined) {
        data.ringRoad = this.ringRoad;
      }
      if (this.$route.params.id) {
        data._id = this.$route.params.id;
      }
      await this.postAddress(data);
      if (this.allAddress.status == 200 || this.allAddress.status == 201) {
        this.snackbar = true;
        this.message = this.allAddress.data.message;
      } else {
        this.snackbar = true;
        this.color = "red";
        this.message = this.allAddress.data.message;
      }
      this.loading = false;
      this.$router.push("/address");
    },
  },
  computed: {
    ...mapGetters(["allAddress"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.getSingleAddress(this.$route.params.id);
      this.fullname = this.allAddress.results.fullname;
      this.addressType = this.allAddress.results.addressType;
      this.email = this.allAddress.results.email;
      this.phone1 = this.allAddress.results.phone1;
      this.phone2 = this.allAddress.results.phone2;
      this.province = this.allAddress.results.province;
      this.address = this.allAddress.results.address;
      this.nearestLandmark = this.allAddress.results.nearestLandmark;
      if (this.allAddress.results.valley != undefined) {
        this.valley = this.allAddress.results.valley;
      }
      if (this.allAddress.results.ringRoad != undefined) {
        this.ringRoad = this.allAddress.results.ringRoad;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  min-height: 50px;
  margin-bottom: 15px;
  width: 200px;
  color: #fff !important;
  background: #0878d4 !important;
  box-shadow: none !important;
}
</style>
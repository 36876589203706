<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Change Password</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="3">
            <Navigation />
          </v-col>
          <v-col md="9" cols="12">
            <div class="filterationBox">
              <h2 class="mb-5">Add Address</h2>
              <AddressForm />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import AddressForm from "@/components/user/AddressForm";
export default {
  name: "AddAddressForm",
  data: () => ({
    oldPassword: undefined,
    newPassword: undefined,
    confirmNewPassword: undefined,
    loading: false,
    snackbar: false,
    errorMessage: undefined,
  }),
  components: {
    Navigation,
    AddressForm,
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.errorMessage {
  margin-top: -20px;
  margin-bottom: 10px;
  color: red;
}
.v-btn {
  min-height: 50px;
  margin-bottom: 15px;
  width: 200px;
  color: #fff !important;
  background: #0878d4 !important;
  box-shadow: none !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .detailList {
    padding-left: 0;
    margin-bottom: 10px;
    li {
      color: #8c8c8c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
</style>